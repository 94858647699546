import axios from '@axios'

export default {
  namespaced: true,
  state: {
    users: [],
  },
  getters: {},
  mutations: {},
  actions: {
    async fetchDocuments(context, payload) {
      return axios.get('docs', payload)
    },
    async fetchUsers(context, payload) {
      return axios.get('user', payload)
    },
    async deleteDocument(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        text: 'Удалить документ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios.delete(`docs/${payload.id}`)
            .then(response => response)
        },
      }).then(result => {
        if (result.isConfirmed) {
          if (result.value) {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$swal({
              icon: 'success',
              text: 'Документ удален',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } else {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$swal({
              icon: 'error',
              text: 'Возникла непредвиденная ошибка. Попробуйте еще раз',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }

          return true
        }

        return false
      })
    },
    async addDocument(context, payload) {
      context.commit('app/SET_LOADING_CREATE', true, { root: true })

      return axios.post('docs', payload)
        .then(response => {
          context.commit('app/SET_LOADING_CREATE', false, { root: true })

          return response
        })
        .catch(() => {
          context.commit('app/SET_LOADING_CREATE', false, { root: true })
          return false
        })
    },
    async updateDocument(context, payload) {
      context.commit('app/SET_LOADING_CREATE', true, { root: true })

      return axios.put(`docs/${payload.id}`, {
        ...payload.data,
      })
        .then(response => {
          context.commit('app/SET_LOADING_CREATE', false, { root: true })

          return response
        })
        .catch(() => {
          context.commit('app/SET_LOADING_CREATE', false, { root: true })
          return false
        })
    },
    async blockDoc(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        text: `Заблокировать документ "${payload.name}"?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios.put(`docs/${payload.id}`, {
            status: 0,
          })
        },
      }).then(result => {
        if (result.isConfirmed) {
          const { data } = result.value
          if (data) {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$swal({
              icon: 'success',
              text: `Документ "${payload.name}" заблокирован`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }

        return result
      })
    },
    async unBlockDoc(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        text: `Разаблокировать документ "${payload.name}"?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios.put(`docs/${payload.id}`, {
            status: 10,
          })
        },
      }).then(result => {
        if (result.isConfirmed) {
          const { data } = result.value
          if (data) {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$swal({
              icon: 'success',
              text: `Документ "${payload.name}" разаблокирован`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }

        return result
      })
    },
    async unconfirmDoc(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        text: `Снять подтверждение с документа "${payload.name}"?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios.put(`docs/${payload.id}`, {
            status: 9,
          })
        },
      }).then(result => {
        if (result.isConfirmed) {
          const { data } = result.value
          if (data) {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$swal({
              icon: 'success',
              text: `Подтверждение с документа "${payload.name}" снято`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }

        return result
      })
    },
    async confirmDoc(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        text: `Подтвердить документ "${payload.name}"?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios.put(`docs/${payload.id}`, {
            status: 10,
          })
        },
      }).then(result => {
        if (result.isConfirmed) {
          const { data } = result.value
          if (data) {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$swal({
              icon: 'success',
              text: `Документ "${payload.name}" подтвержден`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }

        return result
      })
    },
  },
}
