<template>
  <b-sidebar
    id="add-new-document-sidebar"
    :visible="isAddNewDocumentSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-document-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <b-overlay
        :show="$store.state.app.loadingCreate"
        no-wrap
        :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
      />
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ documentData.id ? $t('Update Document') : $t('Add Document') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- name -->
          <validation-provider
            #default="validationContext"
            name="Заголовок"
            rules="required"
          >
            <b-form-group
              label="Заголовок"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="documentData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- user_id -->
          <validation-provider
            v-if="$store.state.app.user.role === 'admin'"
            #default="validationContext"
            name="Публиковать для"
            rules="required"
          >
            <b-form-group
              label="Публиковать для"
              label-for="user_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="documentData.user_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="documentData.user ? [...users, documentData.user] : users"
                :clearable="false"
                :filterable="false"
                input-id="user"
                :reduce="val => val.id"
                :get-option-label="option => option.fio"
                @search="searchUser"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template
                    v-if="searching"
                  >
                    По запросу <em>{{ search }}</em> ничего не найдено.
                  </template>
                  <em
                    v-else
                    style="opacity: 0.5;"
                  >Начните вводить текст, чтобы найти пользователя.</em>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Комментарий"
          >
            <b-form-group
              label="Комментарий"
              label-for="comment"
            >
              <b-form-textarea
                id="comment"
                v-model="documentData.comment"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Файл"
          >
            <b-form-group
              label="Файл"
              label-for="file"
            >
              <b-form-file
                id="file"
                ref="refInputEl"
                placeholder="Выбрать файл"
                @input="inputFile"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ documentData.id ? $t('Update') : $t('Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BFormInvalidFeedback,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required,
  alphaNum,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { debounce } from 'lodash'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewDocumentSidebarActive',
    event: 'update:is-add-new-document-sidebar-active',
  },
  props: {
    isAddNewDocumentSidebarActive: {
      type: Boolean,
      required: true,
    },
    documentData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  setup(props, { emit }) {
    const server = process.env.VUE_APP_SERVER

    const blankDocument = {
      id: null,
      user_id: '',
      name: '',
      comment: '',
      link: '',
      file: '',
    }

    const resetdocumentData = () => {
      /* eslint-disable */
      props.documentData.id = blankDocument.id
      props.documentData.user_id = blankDocument.user_id
      props.documentData.name = blankDocument.name
      props.documentData.comment = blankDocument.comment
      props.documentData.link = blankDocument.link
      props.documentData.file = blankDocument.file
      /* eslint-disable */
    }

    const refInputEl = ref(null)
    const inputFile = () => {
      store.dispatch('app/uploadFile', {
        file: refInputEl.value.files[0],
      }).then(res => {
        if (res.filename) {
          // eslint-disable-next-line no-param-reassign
          props.documentData.file = res.filename
          props.documentData.link = `${server}/${res.filename}`
        }
      })
    }

    const users = ref([])

    const searchUser = debounce(v => {
      if (v) {
        store.dispatch('app-documents/fetchUsers', {
          params: {
            filter: JSON.stringify({
              role: 'client',
              fio: v ? {
                $regex: v,
                $options: 'i',
              } : undefined,
            }),
          },
        })
          .then(response => {
            const { data } = response.data
            users.value = data
          })
          .catch(() => {
            users.value = []
          })
      }
    }, 500)

    const onSubmit = () => {
      if (props.documentData.id) {
        store.dispatch('app-documents/updateDocument', {
          id: props.documentData.id,
          data: {
            user_id: props.documentData.user_id,
            name: props.documentData.name,
            comment: props.documentData.comment,
            link: props.documentData.link,
            file: props.documentData.file,
          },
        })
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-document-sidebar-active', false)
          })
      } else {
        if (store.state.app.user.role === 'client') {
          /* eslint-disable */
          props.documentData.user_id = store.state.app.user.id
          /* eslint-enable */
        }
        store.dispatch('app-documents/addDocument', {
          user_id: props.documentData.user_id,
          name: props.documentData.name,
          comment: props.documentData.comment,
          link: props.documentData.link,
          file: props.documentData.file,
        })
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-document-sidebar-active', false)
          })
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetdocumentData)

    return {
      onSubmit,
      users,
      searchUser,

      refFormObserver,
      getValidationState,
      resetForm,
      refInputEl,
      inputFile,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-document-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
