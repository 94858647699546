<template>
  <div>
    <documents-list-add-new
      :is-add-new-document-sidebar-active.sync="isAddNewDocumentSidebarActive"
      :document-data="document"
      @refetch-data="refetchData"
    />
    <documents-list-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    />
    <b-card>
      <div class="mb-1">
        <b-row>
          <b-col
            class="d-flex justify-content-end"
            md="12"
          >
            <b-button
              variant="outline-primary"
              @click="isAddNewDocumentSidebarActive = true"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('Add') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="relative">
        <b-overlay
          :show="$store.state.app.loading"
          no-wrap
          :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
        />
        <b-table
          ref="refDocumentsListTable"
          class="position-relative"
          :items="documentsData"
          responsive
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          primary-key="id"
          show-empty
          empty-text="Нет данных"
        >
          <template #cell(createdAt)="data">
            <span class="text-nowrap">
              {{ data.item.createdAt | moment("DD MMMM GGGG") }}
            </span>
          </template>
          <!-- Column: User -->
          <template #cell(user)="data">
            <b-media v-if="data.item.user" vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.user.avatar && `${server}/${data.item.user.avatar}`"
                  :text="avatarText(data.item.user.fio)"
                  variant="light-primary"
                  :to="{ name: 'user-view', params: { id: data.item.user.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'user-view', params: { id: data.item.user.id } }"
                class="font-weight-bold"
              >
                {{ data.item.user.fio }}
              </b-link>
            </b-media>
          </template>
          <template #cell(name)="data">
            <div>
              <h6 v-html="data.item.name" />
            </div>
          </template>
          <template #cell(link)="data">
            <b-button
              target="blank"
              variant="flat-primary"
              :href="data.item.link"
            >
              <span class="align-middle">{{ $t('Download') }}</span>
            </b-button>
          </template>
          <template #cell(status)="data">
              <b-badge
                pill
                :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                class="text-capitalize"
              >
                {{ resolveUserStatusText(data.item.status) }}
              </b-badge>
            </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="editDocument(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('Edit') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.status !== 0"
                @click="blockDoc(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('Block') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="data.item.status !== 10 && data.item.status !== 9"
                @click="unBlockDoc(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('Unblock') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="data.item.status === 9"
                @click="confirmDoc(data.item)"
              >
                <feather-icon icon="CheckSquareIcon" />
                <span class="align-middle ml-50">{{ $t('Confirm') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="data.item.status === 10"
                @click="unconfirmDoc(data.item)"
              >
                <feather-icon icon="XSquareIcon" />
                <span class="align-middle ml-50">{{ $t('Remove confirmation') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteDocument(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('Delete') }}</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>
        </b-table>
      </div>
      <div>
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} - {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BBadge,
  BButton,
  BMedia, BAvatar, BLink,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import useDocuments from './useDocuments'
import documentsStoreModule from '../documentsStoreModule'
import DocumentsListFilters from './DocumentsListFilters.vue'
import DocumentsListAddNew from './DocumentsListAddNew.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BBadge,
    BButton,
    BMedia,
    BAvatar,
    BLink,
    DocumentsListFilters,
    DocumentsListAddNew,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-documents'
    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, documentsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const {
      isAddNewDocumentSidebarActive,
      refDocumentsListTable,
      documentsData,
      document,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      resolveUserStatusVariant,
      resolveUserStatusText,

      statusOptions,
      statusFilter,

      deleteDocument,
      updateDocumentStatus,
      fetchDocuments,
      refetchData,
      editDocument,
      blockDoc,
      unBlockDoc,
      confirmDoc,
      unconfirmDoc,
    } = useDocuments()

    fetchDocuments()

    return {
      refDocumentsListTable,
      isAddNewDocumentSidebarActive,
      documentsData,
      document,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      resolveUserStatusVariant,
      resolveUserStatusText,

      statusOptions,
      statusFilter,

      deleteDocument,
      updateDocumentStatus,
      refetchData,
      editDocument,
      blockDoc,
      unBlockDoc,
      confirmDoc,
      unconfirmDoc,

      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
