import { t } from '@core/libs/i18n/utils'
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

export default function usePeriodCalculations() {
  const isAddNewDocumentSidebarActive = ref(false)
  const refDocumentsListTable = ref(null)
  const tableColumns = [
    {
      key: 'createdAt', sortable: false, label: t('Date'), thClass: 'text-left', tdClass: 'text-left',
    },
    {
      key: 'user', sortable: true, label: 'Клиент', thClass: 'text-left',
    },
    {
      key: 'name', sortable: false, label: 'Название', thClass: 'text-left',
    },
    {
      key: 'comment', sortable: false, label: 'Комментарий', thClass: 'text-left',
    },
    {
      key: 'link', sortable: false, label: 'Ссылка', thClass: 'text-left', tdClass: 'text-left',
    },
    {
      key: 'status', sortable: false, label: t('Status'), thClass: 'text-left', tdClass: 'text-left',
    },
    { key: 'actions', label: '', sortable: false },
  ]
  const statusOptions = [
    { label: 'Заблокирован', value: '0' },
    { label: 'Активен', value: '10' },
    { label: 'Не подтвержден', value: '9' },
  ]
  const statusFilter = ref('10')
  const documentsData = ref([])
  const blankDocument = {}
  const document = ref(JSON.parse(JSON.stringify(blankDocument)))
  const perPage = ref(25)
  const total = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refDocumentsListTable.value ? refDocumentsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: total.value,
    }
  })

  const fetchDocuments = () => {
    store.dispatch('app-documents/fetchDocuments', {
      params: {
        page: currentPage.value,
        perPage: perPage.value,
        filter: JSON.stringify({
          status: statusFilter.value,
        }),
      },
    })
      .then(response => {
        const { data, count } = response.data
        documentsData.value = data
        total.value = count
      })
      .catch(() => {
        documentsData.value = undefined
      })
  }

  const refetchData = () => {
    fetchDocuments()
  }

  const deleteDocument = doc => {
    store.dispatch('app-documents/deleteDocument', doc).then(res => {
      if (res) {
        fetchDocuments()
      }
    })
  }

  const updateDocumentStatus = item => {
    store.dispatch('app-documents/updateDocumentStatus', item).then(res => {
      if (res) {
        fetchDocuments()
      }
    })
  }

  const blockDoc = doc => {
    store.dispatch('app-documents/blockDoc', doc).then(() => {
      refetchData()
    })
  }

  const unBlockDoc = doc => {
    store.dispatch('app-documents/unBlockDoc', doc).then(() => {
      refetchData()
    })
  }

  const unconfirmDoc = doc => {
    store.dispatch('app-documents/unconfirmDoc', doc).then(() => {
      refetchData()
    })
  }

  const confirmDoc = doc => {
    store.dispatch('app-documents/confirmDoc', doc).then(() => {
      refetchData()
    })
  }

  const resolveUserStatusVariant = status => {
    if (status === 0) return 'warning'
    if (status === 10) return 'success'
    if (status === 9) return 'secondary'
    return 'primary'
  }

  const resolveUserStatusText = status => {
    if (status === 0) return 'Заблокирован'
    if (status === 10) return 'Активен'
    if (status === 9) return 'Не подтвержден'
    return 'primary'
  }

  const editDocument = doc => {
    isAddNewDocumentSidebarActive.value = true
    document.value = { ...doc }
  }

  watch([currentPage, statusFilter], () => {
    fetchDocuments()
  })

  return {
    isAddNewDocumentSidebarActive,
    refDocumentsListTable,
    statusOptions,
    documentsData,
    document,
    tableColumns,
    perPage,
    currentPage,
    total,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    statusFilter,

    resolveUserStatusVariant,
    resolveUserStatusText,

    fetchDocuments,
    deleteDocument,
    updateDocumentStatus,
    editDocument,
    refetchData,
    blockDoc,
    unBlockDoc,
    confirmDoc,
    unconfirmDoc,
  }
}
